<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ appConfig.companyName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            ยินดีต้อนรับเข้าสู่ระบบ <br>{{ appConfig.companyAppName }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            <!-- Please sign-in to your account and start the adventure -->
            กรุณาเข้าสู่ระบบเพื่อทำการจัดการข้อมูล
          </b-card-text>

          <!-- <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small
                  class="mr-50"
                  ß
                ><span class="font-weight-bold">Admin:</span> admin@demo.com |
                  admin</small>
              </p>
              <p>
                <small
                  class="mr-50"
                ><span class="font-weight-bold">Client:</span> client@demo.com
                  | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- identifier -->
              <b-form-group
                label="Identifier"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Identifier"
                  vid="identifier"
                  rules="required"
                >
                  <b-form-input
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- email -->
              <!-- <b-form-group
                label=""
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">รหัสผ่าน</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>ลืมรหัสผ่าน?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  จำการเข้าสู่ระบบ
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loading"
              >
                <span v-if="loading"><b-spinner small /> Loading...</span>
                <span v-else>เข้าสู่ระบบ</span>
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>ผู้ใช้งานใหม่? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;สร้างบัญชีผู้ใช้งาน</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import appConfig from '@/constants/company'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: appConfig.loginBanner,
      // sideImg: required("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,

      // config
      appConfig,

      // loading
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");   // dark mode
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.loading = true
      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          useJwt
            .login({
              identifier: this.userEmail,
              password: this.password,
            })
            .then(response => {
              console.log('res login',response);
              if (response && response.status === 200 && response.data.user.role.name === 'special') {
                const { user, jwt } = response.data
                user.ability = [{ action: 'manage', subject: 'all' }]
                console.log(response.data)
                useJwt.setToken(jwt)
                useJwt.setRefreshToken(jwt)
                localStorage.setItem('userData', JSON.stringify(user))
                this.$ability.update(user.ability)

                // ? This is just for demo purpose as well.
                // ? Because we are showing eCommerce app's cart items count in navbar
                // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router
                  .replace(getHomeRouteForLoggedInUser('admin'))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${user.fullName || user.username}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `You have successfully logged in as ${user.role}. Now you can start to explore!`,
                      },
                    })
                  })

                // const { userData } = response.data
                // console.log(userData)
                // useJwt.setToken(response.data.accessToken)
                // useJwt.setRefreshToken(response.data.refreshToken)
                // localStorage.setItem('userData', JSON.stringify(userData))
                // this.$ability.update(userData.ability)

                // // ? This is just for demo purpose as well.
                // // ? Because we are showing eCommerce app's cart items count in navbar
                // this.$store.commit(
                //   'app-ecommerce/UPDATE_CART_ITEMS_COUNT',
                //   userData.extras.eCommerceCartItemsCount,
                // )
                // console.log(getHomeRouteForLoggedInUser(userData.role))

                // // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                // this.$router
                //   .replace(getHomeRouteForLoggedInUser(userData.role))
                //   .then(() => {
                //     this.$toast({
                //       component: ToastificationContent,
                //       position: 'top-right',
                //       props: {
                //         title: `ยินดีต้อนรับ ${userData.fullName
                //           || userData.username}`,
                //         icon: 'CoffeeIcon',
                //         variant: 'success',
                //         text: `เข้าสู่ระบบโดย ${userData.role} สำเร็จ สามารถเริ่มต้นการทำงานของคุณได้ทันที`,
                //       },
                //     })
                //   })
              } else {
                throw 'คุณไม่ได้รับอนุญาตให้ใช้งาน กรุณาติดต่อผู้ดูแลระบบ'
              }
            })
            .catch(error => {
              console.log('error', error)
              if (error) {
                // this.$refs.loginValidation.setErrors(
                //   error.response.data.message[0].messages[0].message
                // );
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'เข้าสู่ระบบไม่สำเร็จ',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: `${error.response && error.response.data && error.response.data.message[0] && error.response.data.message[0].messages[0] && error.response.data.message[0].messages[0].message ? error.response.data.message[0].messages[0].message: error}`,
                  },
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
          /* ======================= */
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
